import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-scroll';

export default function FullButton({ title, to, border, action }) {
  return (
    <StyledLink onClick={action} to={to} smooth={true} duration={500} className="animate pointer" border={border}>
      {title}
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  display: inline-block;

  background-image: linear-gradient(#000, #292929);
  border: 1px solid #000;
  border-radius: 12px;
  width: 200px;
  padding: 20px 40px;
  text-align: center;
  font-family: Brockmann Webfont, sans-serif;
  font-size: 17px;
  transition: box-shadow 0.6s, background-color 0.6s;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.31), inset 0 2px 1px rgba(255, 255, 255, 0.5);
  text-decoration: none;
  cursor: pointer;

  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  color: 000 !important;
  &:hover {
    background-image: linear-gradient(#292929, #292929);
    box-shadow: 0 7px 8px rgba(0, 0, 0, 0.31), inset 0 2px 1px rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;
