import React from "react";
import styled from "styled-components";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Pricing from "../components/Sections/Pricing";
import Footer from "../components/Sections/Footer"
import FAQ from "../components/Sections/FAQ";
import PromoBanner from "../components/Sections/PromoBanner";

export default function Landing() {
  return (
    <>
    <PromoBanner />
    <Wrapper>
      <TopNavbar />
      <Header />
      <Services />
      <Projects />
      <Pricing />
      <FAQ />
      <Footer />
    </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
    background-image: url(https://cdn.prod.website-files.com/5837424ae11409586f837994/65da2485ff5fcdc97cd23ac3_dots.svg),url(https://cdn.prod.website-files.com/5837424ae11409586f837994/65e0c422ce2b0db3548c99a5_Frame%202316.png);
    background-position: 0 0,0 0;
    background-size: auto,auto;
    border-radius: 36px;
    margin-left: auto;
    margin-right: auto;

    & section {
      background-color: transparent;
    }
`;


