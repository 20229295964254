
import React from 'react';
import styled from 'styled-components';

export default function PromoBanner() {
  return (
    <Wrapper id="home" className="flexSpaceCenter">
      <p>We are beta testing our web app. <a href="https://scriptstream.bubbleapps.io/version-test/" target='blank'>Sign up today </a>to start receiving FREE scripts from our team while we get ready for launch.</p>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  display: flex;
  position: fixed;
top: 60px;
left: 0;
right: 0;
z-index: 9900;
background-color:rgb(45 45 45) !important;
    justify-content: center;
    align-items: center;
    color: #fff;
  width: 100vw;
  height: 60px;
  padding: 0 !important;
  margin: 0 !important;

  a {
    color: orange;
  }
`;
