import React, { useState } from 'react';
import styled from 'styled-components';

import FullButton from '../Buttons/FullButton';
const faqData = [
  {
    question: "Why wouldn't I just hire a full-time scripter?",
    answer:
      "While hiring a full-time scripter is an option, it comes with significant costs and overhead. The annual salary for a senior scripter can exceed $100,000, plus benefits. Additionally, you may not always have enough work to keep them busy. With ScriptStream, you can access top-tier scripting talent on-demand, paying only for the scripts you need. Our flexible subscription model allows you to pause and resume as needed, ensuring you're only paying when you have work available.",
  },
  {
    question: 'Is there a limit to how many script requests I can have?',
    answer:
      "No, there's no limit! Once subscribed, you can add as many script requests to your queue as you'd like. We'll deliver them one by one, ensuring each script receives the attention it deserves.",
  },
  {
    question: 'How fast will I receive my scripts?',
    answer:
      'On average, most script requests are completed within two to three business days. However, more complex scripts or those requiring extensive research may take longer. We always strive to deliver high-quality, efficient scripts as quickly as possible.',
  },
  {
    question: 'Who are the script writers?',
    answer:
      'ScriptStream employs a team of experienced, professional content writers with diverse backgrounds in various genres. Each professional is thoroughly vetted to ensure they meet our high standards for research, efficiency, and quality.',
  },
  {
    question: 'How does the pause feature work?',
    answer:
      "We understand that your scripting needs may fluctuate. Our pause feature allows you to temporarily halt your subscription when you don't have active script requests. For example, if you use the service for 20 days and then pause, you'll have 11 days of service remaining to use anytime in the future. This ensures you only pay for the time you're actively using our services.",
  },
  {
    question: 'What platforms do you provide content for?',
    answer:
      'ScriptStream supports a wide range of popular social media platforms, including but not limited to YouTube, TikTok, Twitter, Instagram, and Facebook. If you need a script in a specific language, just let us know in your request!',
  },
  {
    question: 'How do I request content?',
    answer:
      "Requesting scripts is easy! You can submit your requests through our user-friendly dashboard, which integrates with Trello. You can describe your needs in text, share documentation, or even record a brief video explaining your requirements. We're flexible and can work with whatever method is most convenient for you.",
  },
  {
    question: "What if I'm not satisfied with a script?",
    answer:
      "Your satisfaction is our top priority. If you're not happy with a delivered script, we'll revise it until it meets your requirements. Our goal is to ensure every script works perfectly for your specific needs and branding.",
  },
  {
    question: "Are there any scripting tasks you don't cover?",
    answer:
      "While we cover a wide range of content needs, there are a few areas we don't typically handle, such as malicious content, content for illegal activities, or extremely obscure topic areas. If you're unsure whether your request falls within our scope, feel free to ask!",
  },
  {
    question: 'What if I only have a single script request?',
    answer:
      "That's not a problem! You can subscribe for the time needed to complete your script, then pause your subscription. You can always reactivate it later when you have more scripting needs. This way, you only pay for the service when you need it.",
  },
  {
    question: "Are there any refunds if I'm not satisfied with the service?",
    answer:
      "We're confident in the quality of our work and strive for 100% customer satisfaction. While we don't typically offer refunds, we are committed to working with you to resolve any issues and ensure you're happy with the scripts we provide. If you have concerns, please reach out to our customer support team.",
  },
  {
    question: "Does your pricing change?",
    answer:
      "Yes, we update pricing based on a number of factors. However, you will never be affected by price changes if you already have a subscription. Pausing locks in your current rate, and you can resume at any time without being subject to new pricing.",
  },
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQWrapper id="faq">
      <Container>
        <FAQFlex>
          <FAQLeft  className='whiteBg p20'>
            <Heading>Commonly asked questions</Heading>
            <FAQList >
              {faqData.map((faq, index) => (
                <FAQRow key={index} onClick={() => toggleFAQ(index)}>
                  <FAQRowLeft>
                    <FAQTitle>{faq.question}</FAQTitle>
                    <FAQAnswer style={{ display: activeIndex === index ? 'block' : 'none' }}>{faq.answer}</FAQAnswer>
                  </FAQRowLeft>
                  <FAQArrow
                    src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65de1a63fe4ae7ccfd483d80_Arrow-Right-1--Streamline-Ultimate.svg"
                    alt=""
                    style={{ transform: `rotate(${activeIndex === index ? '90deg' : '0deg'})` }}
                  />
                </FAQRow>
              ))}
            </FAQList>
          </FAQLeft>
          <CallCard>
            <CallImage src="https://cdn.prod.website-files.com/5837424ae11409586f837994/65de1c417a9d965ed9d7def1_d.png" alt="" />
            <CallHeading>Book a 15-minute intro call</CallHeading>
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <FullButton  style={{margin: '0 auto'}} title="Schedule now" action={() => window.open('https://calendly.com/scriptstream/discovery', '_blank')} />
            </div>
            <ContactText>
              Prefer to email? <ContactLink href="mailto:hello@scriptstream.co">hello@scriptstream.co</ContactLink>
            </ContactText>
          </CallCard>
        </FAQFlex>
      </Container>
    </FAQWrapper>
  );
};

const FAQWrapper = styled.section`
  padding: 80px 0;
  background-color: #f8f8f8;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
`;

const FAQFlex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FAQLeft = styled.div`
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Heading = styled.h2`
  font-size: 32px;
  margin-bottom: 40px;
`;

const FAQList = styled.div``;

const FAQRow = styled.div`
  border-bottom: 1px solid #e0e0e0;
  padding: 20px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FAQRowLeft = styled.div``;

const FAQTitle = styled.p`
  font-weight: bold;
  margin-bottom: 10px;
`;

const FAQAnswer = styled.p`
  color: #666;
  line-height: 1.6;
`;

const FAQArrow = styled.img`
  width: 20px;
  transition: transform 0.3s ease;
`;

const CallCard = styled.div`
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 35%;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
  }
`;

const CallImage = styled.img`
  width: 200px;
  margin: auto;
  display: flex;
  flex: 0 0 auto;
  margin-bottom: 20px;
`;

const CallHeading = styled.h4`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const Button = styled.a`
  display: inline-block;
  background-color: #007bff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const ContactText = styled.div`
  font-size: 14px;
  text-align: center;
  margin-top: 20px;

  &:hover {
    color: black !Important;
  }
`;

const ContactLink = styled.a`
  color: #007bff;
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
    color: black !Important;
  }
`;

export default FAQ;
